var signupStatus = "";
var signinStatus = "";
var signinStatusMessage = "";

let PAGE_ROOT = "/";

var API_BASE_URL;
var PHOTOLIBITEMS_DOWNLOAD_BASE_URL;

var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

if (typeof BASE_URL !== "undefined" && BASE_URL !== "") {
  API_BASE_URL = BASE_URL + "/api/content";
  PHOTOLIBITEMS_DOWNLOAD_BASE_URL = BASE_URL + "/api";
} else {
  API_BASE_URL = "/api/content";
  PHOTOLIBITEMS_DOWNLOAD_BASE_URL = "/api";
}

var pathArray = window.location.pathname.split("/");

// check if on local or on dev server
if (pathArray.includes("pepsi_fsf")) {
  PAGE_ROOT = "/pepsi_fsf/";
} else {
  PAGE_ROOT = "/";
}

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

(function () {
  // Validation for search form in header
  var searchForm = document.getElementById("js-search-form");
  if (searchForm) {
    searchForm.addEventListener(
      "submit",
      function (event) {
        console.log("Event added");
        // console.log("form.checkValidity() :>> ", searchForm.checkValidity());
        if (!searchForm.checkValidity()) {
          searchForm.classList.add("dm-not-valid-form");
          event.preventDefault();
          event.stopPropagation();
        }

        searchForm.classList.add("dm-was-validated");
        searchForm.classList.remove("dm-not-valid-form");

        // ToDo
        // redirect on search page
        // SEARCH_PAGE_SLUG is slug to search page
      },
      false
    );
  }
})();

const VUETIFY_OPTIONS = {
  theme: {
    themes: {
      dark: false,
      light: {
        // primary: "#004C97",
        primary: "#101010",
        pfs_primary: "#004C97",
        pfs_primary_hover: "#005DB8",
        pfs_primary_light: "#E6EEF5",
        pfs_white: "#FFFFFF",
        pfs_grey_lighter: "#F8F8F8",
        pfs_grey_light: "#EFEFEF",
        pfs_grey_middle: "#848484",
        pfs_black: "#101010",
        pfs_cyan: "#0085CA",
        pfs_cyan_light: "#00A9E0",
        pfs_green: "#009639",
        pfs_green_light: "#78BE20",
        pfs_red: "#BC272C",
        pfs_red_dark: "#A6181C",
        pfs_red_light: "#F6E8E8",
        pfs_orange: "#EE6530",
        pfs_orange_dark: "#E62D36",
        pfs_orange_light: "#F5913B",
        pfs_yellow: "#F7CD5A",
      },
    },
  },
};

// Naming pattern - EVERY Vuetify component should be wrapped in a CLASS. The class name should have the "PFS_" prefix
const VUE_LOAD_PAGES = [
  "PFS_loginForm",
  "PFS_featuredArticle",
  "PFS_recipeListing",
  "PFS_productListing",
  "PFS_ContactForm",
  "PFS_SecondaryContactForm",
  "PFS_profileForm",
  "PFS_photoLibraryListing",
  "PFS_newsListing",
  "PFS_brandListing",
  "PFS_savedRecipes",
  "PFS_savedMenus",
  "PFS_serves_counter",
  "PFS_recipe_actions_box",
  "PFS_registrationForm",
  "PFS_registrationConfirmPage",
  "PFS_forgotPasswordForm",
  "PFS_pagesListMenu",
  "PFS_passwordResetRequest",
  "PFS_loginFormV2",
  "PFS_searchResults",
  "PFS_popupPermissions",
  "PFS_Contest",
  "PFS_equipmentListing",
];

function stringTranslation(stringVar) {
  // var e_mail_is_required                                                                 = 'E-mail is required.'
  // var e_mail_must_be_valid                                                               = 'E-mail must be valid.'
  // var password_is_required                                                               = 'Password is required.'
  // var min_8_characters_with_at_least_one_capital_letter_a_number_and_a_special_character = 'Min. 8 characters with at least one capital letter, a number and a special character.'
  // var the_password_confirmation_does_not_match                                           = 'The password confirmation does not match.'
  // var terms_of_service__is_required                                                      = 'Terms of Service  is required.'
  // var first_name_is_required                                                             = 'First name is required.'
  // var surname_is_required                                                                = 'Surname is required.'
  // var position_is_required                                                               = 'Position is required.'
  // var type_of_restaurant_is_required                                                     = 'Type of Restaurant is required.'
  // var address__is_required                                                               = 'Address  is required.'
  // var this_field_is_required                                                             = 'This field is required.'
  // var only_numbers                                                                       = 'Only numbers.'
  // var must_be_                                                                           = 'Must be '
  // var _characters                                                                        = ' characters'
  // var restaurant_type_is_required                                                        = 'Restaurant Type is required.'
  // var restaurant_name_is_required                                                        = 'Restaurant Name is required.'
  // var restaurant_address_is_required                                                     = 'Restaurant address is required.'
  // var postal_code_is_required                                                            = 'Postal code is required.'
  // var name_is_required                                                                   = 'Name is required.'
  // var your_position_is_required                                                          = 'Your position is required.'
  // var phone_number_is_required                                                           = 'Phone Number is required.'
  // var min_8_characters                                                                   = 'Min 8 characters.'
  // var message_is_required                                                                = 'Message is required.'
  // var we_apologize_something_went_wrong_please_try_again_later                           = 'We apologize, something went wrong. Please try again later'

  var e_mail_is_required = "Adresse e-mail requise.";
  var e_mail_must_be_valid = "L'adresse e-mail doit-être valide.";
  var password_is_required = "Mot de passe requis.";
  var min_8_characters_with_at_least_one_capital_letter_a_number_and_a_special_character =
    "Min. 8 caractères avec au moins une lettre en capitale, un chiffre et un caractère spécial.";
  var the_password_confirmation_does_not_match =
    "La confirmation du mot de passe ne correspond pas.";
  var terms_of_service__is_required =
    "Acceptation des Conditions Générales d'Utilisations requise.";
  var first_name_is_required = "Nom requis.";
  var surname_is_required = "Prénom requis.";
  var position_is_required = "Profession requise.";
  var type_of_restaurant_is_required = "Type de restauration requis.";
  var address__is_required = "Adresse requise.";
  var this_field_is_required = "Ce champs est requis.";
  var only_numbers = "Chiffre uniquement";
  var must_be_ = "Doit être ";
  var _characters = " caractères";
  var restaurant_type_is_required = "Type de restauration requis.";
  var restaurant_name_is_required = "Nom de l'établissement requis.";
  var restaurant_address_is_required =
    "L'adresse de l'établissement est requise.";
  var postal_code_is_required = "Le code postal est requis.";
  var name_is_required = "Nom requis.";
  var your_position_is_required = "Votre profession est requise.";
  var phone_number_is_required = "Numéro de téléphone requis.";
  var min_8_characters = "Min. 8 caractères.";
  var there_seems_to_be_some_technical_error =
    "Une erreur technique est survenue.";
  var message_is_required = "Le message est requis";
  var we_apologize_something_went_wrong_please_try_again_later =
    "Nous sommes désolé, une erreur est survenue. Merci de réessayer plus tard.";
  var file_extension_must_be_png =
    "Le fichier uploadé doit être au format .png, .jpg ou .jpeg";

  return eval(stringVar);
}

function downloadFile(url, title, size) {
  if (title == "") {
    title = "logo";
  }
  var link = document.createElement("a");
  link.href = url;
  // link.download = title + '-' + size;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

document.addEventListener("DOMContentLoaded", function () {
  VUE_LOAD_PAGES.forEach((item) => {
    if (document.getElementById(item) !== null) {
      // console.log(item);
      // find object
      var fn = window[item];

      // is object a function?
      if (typeof fn === "function") fn();
    }
  });
});

// Component for displaying card with delete functionality
// Used in profile-saved-menu.html & profile-saved-recipes.html
// HTML variables API_DELETE_PATH are required
var DeletableCardComponent = {
  props: ["id", "name", "text", "thumbnail", "url"],
  data() {
    return {
      delete_dropdown_id: null,
      show: true,
    };
  },
  computed: {
    apiUrl: function () {
      return BASE_URL + API_DELETE_PATH;
      // return 'http://localhost/pepsico-fsf-frontend/api/delete.php'
    },
  },
  methods: {
    handleDeleteDropdown(id) {
      if (id === this.delete_dropdown_id) {
        this.delete_dropdown_id = null;
      } else {
        this.delete_dropdown_id = id;
      }
    },
    deleteItem(id) {
      if (typeof API_DELETE_PATH !== "undefined") {
        // the API_DELETE_PATH is defined
        fetch(this.apiUrl, {
          method: "POST",
          body: "{ id: " + id + " }",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              this.$emit("item-deleted", false);
              this.delete_dropdown_id = null;
              throw Error(response.statusText);
            }

            return response.json();
          })
          .then((data) => {
            if (data.status != "Success") {
              this.$emit("item-deleted", false);
              this.delete_dropdown_id = null;
              throw Error(data.message);
            }

            this.$emit("item-deleted", true);
            // Hide deleted item
            this.show = false;
          })
          .catch((error) => {
            this.$emit("item-deleted", false);
            this.delete_dropdown_id = null;
            console.log(error);
          });
      } else {
        console.log("Delete path is missing");
      }
    },
  },
};

var getDataMixin = {
  data() {
    return {
      isLoading: false,
    };
  },
  created: function () {
    this.getData(this.apiUrl, true);
  },
  methods: {
    getData(url, reset) {
      this.isLoading = true;
      fetch(url)
        .then((response) => {
          if (!response.ok) throw Error(response.statusText);
          return response.json();
        })
        .then((data) => {
          if (reset) {
            this.itemList = data.Results;
          } else {
            for (var i of data.Results) {
              this.itemList.push(i);
            }
          }
          this.totalResults = data.TotalResults;
          this.totalPages = data.TotalPages;
          this.currentlyLoaded = this.itemList.length;
          this.progress_percent = (
            (this.currentlyLoaded / this.totalResults) *
            100
          ).toFixed();

          if (this.totalResults > this.size) {
            if (this.page == this.totalPages) {
              this.hide_paginator = true;
            } else {
              this.hide_paginator = false;
            }
          } else {
            this.progress_percent = 100;
            this.hide_paginator = true;
          }

          this.isLoading = false;
        });
    },
    updateProgressbar() {
      if (this.currentlyLoaded > 0) {
        this.currentlyLoaded--;
        this.totalResults--;
      }
      this.progress_percent = (
        (this.currentlyLoaded / this.totalResults) *
        100
      ).toFixed();
    },
  },
};

function PFS_pagesListMenu() {
  new Vue({
    el: "#PFS_pagesListMenu",
    data: () => ({
      items: [
        { text: "Index", link: "index" },
        { text: "Photo library", link: "photo-library" },
        { text: "Search results", link: "search-results" },
        { text: "Contact", link: "contact" },
        { text: "Coming soon", link: "coming-soon" },
        { text: "Menu generator intro", link: "menu-generator-intro" },
        { text: "Simple text", link: "simple-text" },

        { type: "spacer", text: "User links" },
        { text: "Login", link: "login" },
        { text: "Forgot password", link: "forgot-password-form" },
        { text: "Registration", link: "registration" },
        { text: "Menu generator", link: "menu-generator" },
        { text: "Profile", link: "profile" },
        { text: "Profile saved menu", link: "profile-saved-menu" },
        { text: "Profile saved recipes", link: "profile-saved-recipes" },

        { type: "spacer", text: "Recette links" },
        { text: "Recipe listing", link: "recipe-listing" },
        { text: "Recipe detail", link: "recipe-detail" },
        { text: "Recette -Doritos Nuggets", link: "Recette--Doritos-Nuggets" },
        {
          text: "Recette -Doritos Loaded Fries",
          link: "Recette--Doritos-Loaded-Fries",
        },
        {
          text: "Recette -Doritos Hot Nachos",
          link: "Recette--Doritos-Hot-Nachos",
        },
        { text: "Recette -Doritos Burger", link: "Recette--Doritos-Burger" },
        {
          text: "Recette -Doritos Bolognaise",
          link: "Recette--Doritos-Bolognaise",
        },
        {
          text: "Recette Alvalle -TARTINABLE METRO",
          link: "Recette--Alvalle--TARTINABLE-METRO",
        },
        {
          text: "Recette Alvalle -PANNACOTTA METRO",
          link: "Recette--Alvalle--PANNACOTTA-METRO",
        },
        {
          text: "Recette Alvalle -GARNITURES METRO",
          link: "Recette--Alvalle--GARNITURES-METRO",
        },
        {
          text: "Recette Alvalle -BURRATA METRO",
          link: "Recette--Alvalle--BURRATA-METRO",
        },

        { type: "spacer", text: "Brand links" },
        { text: "Brand listing", link: "brand-listing" },
        { text: "Brand detail", link: "brand-detail" },
        { text: "Brand detail - Alvalle", link: "brand-detail--alvalle" },
        { text: "Brand detail - Doritos 404", link: "brand-detail--doritos" },
        { text: "Brand detail - Lay’s 404", link: "brand-detail--lays" },
        { text: "Brand detail - Quaker 404", link: "brand-detail--quaker" },
        {
          text: "Brand detail - Tropicana 404",
          link: "brand-detail--tropicana",
        },
        { text: "Brand detail - Pepsi 404", link: "brand-detail--pepsi" },
        { text: "Brand detail - Lipton 404", link: "brand-detail--lipton" },
        { text: "Brand detail - 7UP 404", link: "brand-detail--7up" },

        { type: "spacer", text: "News links" },
        { text: "More content", link: "more-content" },
        { text: "News listing", link: "news-listing" },
        { text: "News detail", link: "news-detail" },
        { text: "News detail 1", link: "news-detail-1" },

        { type: "spacer", text: "Product links" },
        { text: "Product listing", link: "product-listing" },
        // { text: "Product detail", link: "product-detail" },
        {
          text: "Product detail - Doritos Nature 454G",
          link: "product-detail--Doritos-Nature-454G",
        },
        {
          text: "Product detail - Sauce Doritos Salsa 1,2KG",
          link: "product-detail--Sauce-Doritos-Salsa-1-2KG",
        },
        {
          text: "Product detail - Sauce Doritos Nacho Cheese 1,2KG",
          link: "product-detail--Sauce-Doritos-Nacho-Cheese-1-2KG",
        },
        {
          text: "Product detail - Toutes nos tortillas Doritos",
          link: "product-detail--Toutes-nos-tortillas-Doritos",
        },
      ],
    }),
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_registrationConfirmPage() {
  new Vue({
    el: "#PFS_registrationConfirmPage",
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_loginFormV2() {
  new Vue({
    el: "#PFS_loginFormV2",
    data() {
      return {
        isInForm: false,
        isLoading: false,
        step: 0,
        isStepValid_1: false,

        email: null,
        emailRules: [
          (v) => !!v || stringTranslation("e_mail_is_required"),
          (v) => /.+@.+/.test(v) || stringTranslation("e_mail_must_be_valid"),
        ],

        showPass1: false,
        password: "",
        passRules: {
          required: (v) => !!v || stringTranslation("password_is_required"),
        },
      };
    },
    methods: {
      nextStep() {
        this.step++;
      },

      signUpStep() {
        this.isLoading = true;
        const _this = this;
        const data = {
          email: this.email,
          password: this.password,
        };
        jQuery
          .ajax({
            type: "POST",
            url: window.page_data__sign_up_url,
            data: data,
            dataType: "json",
          })
          .done(function () {
            console.log(" success");
          })
          .fail(function (err) {
            console.log("error: ", err);
          })
          .always(function () {
            console.log("finished signUpStep");
            setTimeout(function () {
              _this.nextStep();
              _this.isLoading = false;
            }, 2000);
          });
      },
    },
    watch: {
      step: {
        handler: function (val, oldVal) {
          this.isInForm = val > 0;
        },
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_loginForm() {
  new Vue({
    el: "#PFS_loginForm",
    data: function () {
      return {
        valid: false,
        show_password: false,
        password: "",
        email: "",
        isLoading: false,
        server_error: false,
        server_error_msg: "",
        rules: {
          required: (value) =>
            !!value || stringTranslation("this_field_is_required"),
          min: (v) => v.length >= 8 || stringTranslation("min_8_characters"),
          validEmail: (v) =>
            /.+@.+/.test(v) || stringTranslation("e_mail_must_be_valid"),
        },
      };
    },
    methods: {
      loginUser: function loginUser() {
        this.$refs.loginForm.validate();

        var email = this.email;
        var password = this.password;
        var _this = this;

        if (this.valid) {
          this.isLoading = true;

          jQuery
            .ajax({
              type: "POST",
              url: url_postlogin,
              data: { username: email, password: password },
              dataType: "json",
            })
            .done(function (data) {
              if (data.isLoggedIn == false) {
                _this.server_error = true;
                _this.server_error_msg = data.Item1;
              } else {
                // Reset server error
                _this.server_error = false;
                _this.server_error_msg = "";

                window.location.replace(url_afterlogin);
              }
              _this.isLoading = false;
            })
            .fail(function (err) {
              _this.server_error = true;
              _this.server_error_msg = "Erreur inconnue";
              _this.isLoading = false;
              console.log("error: ", err);
            });
        }
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_forgotPasswordForm() {
  new Vue({
    el: "#PFS_forgotPasswordForm",
    data() {
      return {
        el: "#PFS_forgotPasswordForm",
        isInForm: false,
        isLoading: false,
        step: 0,
        isSuccess: true,
        errorMessage: "",
        isStepValid_1: false,

        //  Step 4
        showPass1: false,
        showPass2: false,

        password: "",
        passwordConfirm: "",
        passRules: {
          required: (v) => !!v || stringTranslation("password_is_required"),
          characters: (v) => {
            const pattern =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
            return (
              pattern.test(v) ||
              stringTranslation(
                "min_8_characters_with_at_least_one_capital_letter_a_number_and_a_special_character"
              )
            );
          },
          confirmPassword: (v) =>
            v === this.password ||
            stringTranslation("the_password_confirmation_does_not_match"),
        },

        tos: 1,
        tosRules: [
          (v) => !!v || stringTranslation("terms_of_service__is_required"),
        ],
      };
    },
    methods: {
      nextStep() {
        this.step++;
      },

      signUpStep() {
        this.isLoading = true;
        const _this = this;
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          position: this.position,
          restaurantType: this.restaurantType,
          address: this.address,
          restaurantName: this.restaurantName,
          zip: this.zip,
        };

        var pepsicoUserObj = {
          UserID: $("#hdnUserId").val(),
          Password: this.password,
        };

        jQuery
          .ajax({
            type: "POST",
            //url: window.page_data__sign_up_url,
            //data: data,
            url: url_resetpasswordconfirm,
            data: pepsicoUserObj,
            dataType: "json",
          })
          .done(function (data) {
            if (data == "Success") {
              console.log("Success");
              _this.isSuccess = true;
            } else {
              _this.isSuccess = false;
              _this.errorMessage = data;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            _this.isSuccess = false;
            _this.errorMessage = err;
          })
          .always(function () {
            console.log("finished signUpStep");
            setTimeout(function () {
              _this.nextStep();

              _this.isLoading = false;
            }, 2000);
          });
      },
    },
    watch: {
      step: {
        handler: function (val, oldVal) {
          this.isInForm = val > 0;
        },
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_passwordResetRequest() {
  new Vue({
    el: "#PFS_passwordResetRequest",
    data() {
      return {
        el: "#PFS_passwordResetRequest",
        isInForm: false,
        isLoading: false,
        email: "",
        step: 0,
        isSuccess: true,
        errorMessage: "",
        isStepValid_1: false,
        emailRules: {
          required: (v) => !!v || stringTranslation("e_mail_is_required"),
          valid: (v) =>
            /.+@.+/.test(v) || stringTranslation("e_mail_must_be_valid"),
        },
      };
    },
    methods: {
      submitForm() {
        var _self = this;

        var pepsicoUserObj = {
          Email: this.email,
        };

        this.isLoading = true;
        setTimeout(function () {
          _self.step = 1;

          jQuery
            .ajax({
              type: "POST",
              url: url_resetpasswordrequest,
              data: pepsicoUserObj,
              dataType: "json",
            })
            .done(function (data) {
              if (data == "Success") {
                console.log("Success");
                _self.isSuccess = true;
              } else {
                _self.isSuccess = false;
                _self.errorMessage = data;
              }
            })
            .fail(function (err) {
              console.log("error: ", err);
              _self.isSuccess = false;
              _self.errorMessage = data;
            })
            .always(function () {
              console.log("finished password Reset");
              _self.isLoading = false;
            });
        }, 1000);
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_registrationForm() {
  new Vue({
    el: "#PFS_registrationForm",

    data() {
      return {
        isLoaded: false,
        direction: "slide-left",
        isInForm: false,
        isLoading: false,
        step: 1,
        siretNumberLength: 14,
        cancelRegistrationDialog: false,
        isStepValid_1: false,
        isStepValid_2: false,
        isStepValid_3: false,
        isStepValid_4: false,
        server_error: false,
        server_error_msg: "",
        // Step 0
        firstName: null,
        firstNameRules: [
          (v) => !!v || stringTranslation("first_name_is_required"),
        ],

        lastName: null,
        lastNameRules: [(v) => !!v || stringTranslation("surname_is_required")],

        email: null,
        emailRules: [
          (v) => !!v || stringTranslation("e_mail_is_required"),
          (v) => /.+@.+/.test(v) || stringTranslation("e_mail_must_be_valid"),
        ],

        // Step 2
        position: null,
        positionRules: [
          (v) => !!v || stringTranslation("position_is_required"),
        ],

        //  Step 3
        restaurantTypes: window.page_data__restaurant_types,
        restaurantTypeRules: [
          (v) => {
            return !!v || stringTranslation("type_of_restaurant_is_required");
          },
        ],

        address: null,
        addressRules: [(v) => !!v || stringTranslation("address__is_required")],

        siretNumber: null,
        siretNumberRules: {
          required: (v) => !!v || stringTranslation("this_field_is_required"),
          numeric: (v) => {
            const pattern = /^[0-9]{14}/;
            return pattern.test(v) || stringTranslation("only_numbers");
          },
          numLength: (v) =>
            (!!v && v.length === this.siretNumberLength) ||
            stringTranslation("must_be_") +
              this.siretNumberLength +
              stringTranslation("_characters"),
        },
        restaurantType: null,
        restaurantTypeRules: [
          (v) => !!v || stringTranslation("restaurant_type_is_required"),
        ],
        restaurantName: null,
        restaurantNameRules: [
          (v) => !!v || stringTranslation("restaurant_name_is_required"),
        ],
        zip: null,
        restaurantZipRules: [
          (v) => !!v || stringTranslation("postal_code_is_required"),
        ],

        //  Step 4
        showPass1: false,
        showPass2: false,

        password: "",
        passwordConfirm: "",
        passRules: {
          required: (v) => !!v || stringTranslation("password_is_required"),
          characters: (v) => {
            const pattern =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@\-\_\(\)\[\]\+\=\{\}\'\"\;\:\.\<\>\,\?\/\|\\#\$%\^&\*])(?=.{8,})/;
            return (
              pattern.test(v) ||
              stringTranslation(
                "min_8_characters_with_at_least_one_capital_letter_a_number_and_a_special_character"
              )
            );
          },
          confirmPassword: (v) =>
            v === this.password ||
            stringTranslation("the_password_confirmation_does_not_match"),
        },

        tos: 1,
        tosRules: [
          (v) => !!v || stringTranslation("terms_of_service__is_required"),
        ],
      };
    },

    methods: {
      idPepsicoEmail(email) {
        const domain = email.substring(email.lastIndexOf("@") + 1);
        return domain === "pepsico.com";
      },

      nextStep() {
        this.direction = "slide-left";

        // Fro pepsico users
        if (this.step === 1 && this.idPepsicoEmail(this.email)) {
          this.position = 2;
          this.step = 4;
        } else {
          this.step++;
        }
      },
      prevStep() {
        this.direction = "slide-right";
        this.step--;
      },
      signUpStep() {
        this.isLoading = true;
        const _this = this;
        $("#sign_status").text("");

        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          position: this.position,
          restaurantType: this.restaurantType,
          address: this.address,
          restaurantName: this.restaurantName,
          siretNumber: this.siretNumber,
          zip: this.zip,
        };

        var pepsicoUserObj = {
          Name: this.firstName,
          SurName: this.lastName,
          Email: this.email,
          UserName: this.email,
          Password: this.password,
          ProfileType: $(
            ".position-radio-btn [value=" + this.position + "]"
          ).attr("value-text"),
          YourEstablishment: $(".your-eastablishment input").val(),
          SiretNumber: this.siretNumber,
          TypeOfRestaurant: this.restaurantType,
          FacilityName: this.restaurantName,
          PostalCode: this.zip,
        };

        // console.log(pepsicoUserObj);

        jQuery
          .ajax({
            type: "POST",
            url: url_postsignup,
            data: pepsicoUserObj,
            dataType: "json",
          })
          .done(function (data) {
            if (data.Item1 == "Success") {
              _this.server_error = false;
              _this.server_error_msg = "";
              _this.isLoading = false;
              _this.nextStep();
            } else {
              _this.server_error = true;
              _this.server_error_msg = data.Item1;
              _this.isLoading = false;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            signupStatus = err;
          });
      },
      cancelRegistration() {
        this.cancelRegistrationDialog = true;
      },
      resetSteps() {
        this.cancelRegistrationDialog = false;
        this.step = 1;
        window.location.replace("/");
      },
      nextStepNoValidation() {
        this.step++;
        console.log("From nextStepNoValidation");
      },

      googleMapAutocomplete() {
        let inputElement = this.$refs.origin__vuetify.$refs.input;
        const autocomplete = new google.maps.places.Autocomplete(inputElement, {
          componentRestrictions: { country: "fr" },
          fields: [
            "address_components",
            "geometry",
            "icon",
            "name",
            "place_id",
            "formatted_address",
          ],
          types: ["establishment"],
        });

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          this.restaurantName = place.name;

          for (var j = 0; j < place.address_components.length; j++) {
            for (var k = 0; k < place.address_components[j].types.length; k++) {
              if (place.address_components[j].types[k] == "postal_code") {
                this.zip = place.address_components[j].short_name;
              }
            }
          }
        });
      },
    },
    watch: {
      step: {
        handler: function (val, oldVal) {
          this.isInForm = val > 0;
        },
        deep: true,
      },
    },

    mounted() {
      this.isInForm = this.step > 0;

      this.$nextTick(function () {
        this.isLoaded = true;
        this.googleMapAutocomplete();
        // Code that will run only after the
        // entire view has been rendered
      });
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_recipe_actions_box() {
  new Vue({
    el: "#PFS_recipe_actions_box",
    data() {
      return {
        isSavedRecipe: window.isSavedRecipe,
      };
    },
    methods: {
      saveOrDeleteRecipe(id) {
        this.isSavedRecipe = !this.isSavedRecipe;
        var recipe_api_base_path = "/pepsicoapi/recipes";
        var recipeActionPath = "/pepsicoapi/recipes/delete";
        if (this.isSavedRecipe) {
          recipeActionPath = recipe_api_base_path + "/save";
        }

        jQuery
          .ajax({
            type: "POST",
            url: recipeActionPath,
            data: { recipeId: id },
            dataType: "json",
          })
          .done(function (data) {
            if (data.Success) {
              console.log("Success");
            } else {
              //error handling ?
              console.log(data.ErrorMessage);
              this.isSavedRecipe = !this.isSavedRecipe; //revert back state
            }
          })
          .fail(function (err) {
            //error handling, or if user is not authorized ?
            this.isSavedRecipe = !this.isSavedRecipe; //revert back state
          })
          .always(function () {
            //todo error handling, show user some message ?
            console.log("Save recipe..");
          });
      },
    },
  });
}

function PFS_serves_counter() {
  var ingredients = window.ingredient_list_html;
  var reference_amount = window.serves_amount_reference_value;

  new Vue({
    el: "#PFS_serves_counter",
    data() {
      return {
        ingredient_list: [],
        ingredientAmount: [],
        serves: reference_amount,
      };
    },
    methods: {
      decreaseAmount() {
        const vm = this;
        if (this.serves > reference_amount) {
          this.serves -= reference_amount;
          const _this = this;

          this.ingredient_list.forEach(function (item, index) {
            if (item.amount) {
              item.amount -= _this.ingredientAmount[index];
              item.amount = Math.round(item.amount * 100) / 100;
            }
          });
        }
      },
      increaseAmount() {
        this.serves += reference_amount;
        const _this = this;

        this.ingredient_list.forEach(function (item, index) {
          if (item.amount) {
            // item.amount += _this.ingredientAmount[index];
            item.amount +=
              Math.round(_this.ingredientAmount[index] * 100) / 100;

            item.amount = Math.round(item.amount * 100) / 100;
          }
        });
      },
    },
    beforeMount() {
      var _this = this;

      this.ingredient_list = ingredients;

      this.ingredient_list.forEach((el) => {
        _this.ingredientAmount.push(el.amount);
      });
    },
  });
}

function PFS_profileForm() {
  new Vue({
    el: "#PFS_profileForm",
    data() {
      return {
        editAccountMode: false,
        editRestaurantMode: false,

        // Menu list
        items: window.page_data__menu_list,
        // Account Fields
        showPass1: false,
        showPass2: false,

        account_valid: false,
        password_valid: false,
        restaurant_valid: false,
        siretNumberLength: 14,
        firstName: window.page_data__first_name,
        lastName: window.page_data__last_name,
        nameRules: [(v) => !!v || stringTranslation("name_is_required")],

        email: window.page_data__email,
        emailRules: [
          (v) => !!v || stringTranslation("e_mail_is_required"),
          (v) => /.+@.+/.test(v) || stringTranslation("e_mail_must_be_valid"),
        ],

        position: window.page_data__position,
        positions: window.page_data__positions,
        positionRules: [
          (v) => v.length > 0 || stringTranslation("your_position_is_required"),
        ],

        password: "",
        passwordConfirm: "",
        passRules: {
          required: (v) => !!v || stringTranslation("password_is_required"),
          characters: (v) => {
            const pattern =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
            return (
              pattern.test(v) ||
              stringTranslation(
                "min_8_characters_with_at_least_one_capital_letter_a_number_and_a_special_character"
              )
            );
          },
          confirmPassword: (v) =>
            v === this.password ||
            stringTranslation("the_password_confirmation_does_not_match"),
        },

        // Restaurant fields
        restaurantName: window.page_data__restaurant_name,
        restaurantNameRules: [
          (v) => !!v || stringTranslation("restaurant_name_is_required"),
        ],

        restaurantAddress: window.page_data__restaurant_address,
        restaurantAddressRules: [
          (v) => !!v || stringTranslation("restaurant_address_is_required"),
        ],

        restaurantZip: window.page_data__restaurant_zip,
        restaurantZipRules: [
          (v) => !!v || stringTranslation("postal_code_is_required"),
        ],

        restaurantPhone: window.page_data__restaurant_phone,
        restaurantPhoneRules: [
          (v) => !!v || stringTranslation("phone_number_is_required"),
        ],
        siretNumber: window.page_data__siret_number,
        siretNumberRules: {
          required: function required(v) {
            return !!v || stringTranslation("this_field_is_required");
          },
          numeric: function numeric(v) {
            var pattern = /^[0-9]{14}/;
            return pattern.test(v) || stringTranslation("only_numbers");
          },
          numLength: function numLength(v) {
            return (
              (!!v && v.length === _this6.siretNumberLength) ||
              stringTranslation("must_be_") +
                _this6.siretNumberLength +
                stringTranslation("_characters")
            );
          },
        },
        restaurantType: window.page_data__restaurant_type,
        restaurantTypes: window.page_data__restaurant_types,
        restaurantTypeRules: [
          (v) => {
            // console.log("v :>> ", v);
            return !!v || stringTranslation("type_of_restaurant_is_required");
          },
        ],

        restaurantAdditionalType: window.page_data__restaurant_additional_type,
        showRestaurantAdditionalType: false,
      };
    },

    methods: {
      editAccount() {
        this.editAccountMode = !this.editAccountMode;
      },
      changePassword() {
        const data = { password: this.password };

        var pepsicoUserObj = {
          UserID: $("#hdnUserId").val(),
          Password: this.password,
        };

        var responseMessage = "";

        jQuery
          .ajax({
            type: "POST",
            url: window.page_data__password_url,
            /*data: data,*/
            data: pepsicoUserObj,
            dataType: "json",
          })
          .done(function (data) {
            console.log(" success");
            responseMessage = data;
          })
          .fail(function (err) {
            console.log("error: ", err);
            responseMessage = err;
          })
          .always(function () {
            console.log("finished changePassword");
            //alert("Password Reset : " + responseMessage);

            if (responseMessage == "Success") {
              //$(".my-account-message").html('<div class="v-messages__message message-transition-enter-to">' + responseMessage + '</div>');
              //alert(responseMessage);
              setTimeout(window.location.reload(), 5000);
            } else {
              $(".change-password-message").html(
                '<div class="v-text-field__details"><div class="v-messages theme--light error--text" role="alert"><div class="v-messages__wrapper"><div class="v-messages__message message-transition-enter-to">' +
                  responseMessage +
                  "</div></div></div></div>"
              );

              //alert(responseMessage);
            }
          });
      },
      editRestaurant() {
        console.log("editRestaurant");
      },
      saveAccount() {
        const data = {
          restaurantName: this.restaurantName,
          restaurantAddress: this.restaurantAddress,
          restaurantZip: this.restaurantZip,
          restaurantPhone: this.restaurantPhone,
          restaurantType: this.restaurantType,
          restaurantAdditionalType: this.restaurantAdditionalType,
        };

        var UserProfile = {
          UserID: $("#hdnUserId").val(),
          Name: this.firstName,
          SurName: this.lastName,
          Email: this.email,
          UserName: "",
          Password: "",
          ProfileType: "",
          ProfileTypes: this.position,
          SiretNumber: this.siretNumber,
          YourEstablishment: this.restaurantAddress,
          TypeOfRestaurant: this.restaurantType,
          TypeOfRestaurantAdditional: this.restaurantAdditionalType,
          FacilityName: this.restaurantName,
          PostalCode: this.restaurantZip,
          PhoneNumber: this.restaurantPhone,
          ProfileActivityLink: "",
          IsMyAccount: true,
          IsMyRestaurant: false,
        };

        var responseMessage = "";
        var response = "";

        jQuery
          .ajax({
            type: "POST",
            url: window.page_data__account_url,
            /*data: data,*/
            data: UserProfile,
            dataType: "json",
          })
          .done(function (data) {
            if (data.response == "Success") {
              response = "Success";
              responseMessage = "Updated Successfully ";
              if (data.userModifyResponse != "Success") {
                responseMessage += data.userModifyResponse;
              }
              if (data.roleModifyResponse != "Success") {
                responseMessage += data.roleModifyResponse;
              }
              if (data.profielModifyResponse != "Success") {
                responseMessage += data.profielModifyResponse;
              }
              console.log("success");
            } else {
              responseMessage = data.response;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            responseMessage = err;
          })
          .always(function () {
            console.log("finished saveAccount");
            if (response == "Success") {
              //$(".my-account-message").html('<div class="v-messages__message message-transition-enter-to">' + responseMessage + '</div>');
              //alert(responseMessage);

              setTimeout(window.location.reload(), 5000);
            } else {
              $(".my-account-message").html(
                '<div class="v-text-field__details"><div class="v-messages theme--light error--text" role="alert"><div class="v-messages__wrapper"><div class="v-messages__message message-transition-enter-to">' +
                  responseMessage +
                  "</div></div></div></div>"
              );

              //alert(responseMessage);
            }
          });
      },
      editRestaurant() {
        this.editRestaurantMode = !this.editRestaurantMode;
      },
      saveRestaurant() {
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          position: this.position,
        };

        var UserProfile = {
          UserID: $("#hdnUserId").val(),
          Name: this.firstName,
          SurName: this.lastName,
          Email: this.email,
          UserName: "",
          Password: "",
          ProfileType: "",
          ProfileTypes: this.position,
          SiretNumber: this.siretNumber,
          YourEstablishment: this.restaurantAddress,
          TypeOfRestaurant: this.restaurantType,
          TypeOfRestaurantAdditional: this.restaurantAdditionalType,
          FacilityName: this.restaurantName,
          PostalCode: this.restaurantZip,
          PhoneNumber: this.restaurantPhone,
          ProfileActivityLink: "",
          IsMyAccount: false,
          IsMyRestaurant: true,
        };

        var responseMessage = "";
        var response = "";

        jQuery
          .ajax({
            type: "POST",
            url: window.page_data__restaurant_url,
            /*data: data,*/
            data: UserProfile,
            dataType: "json",
          })
          .done(function (data) {
            if (data.response == "Success") {
              response = "Success";
              responseMessage = "Updated Successfully";
              if (data.userModifyResponse != "Success") {
                responseMessage += data.userModifyResponse;
              }
              if (data.roleModifyResponse != "Success") {
                responseMessage += data.roleModifyResponse;
              }
              if (data.profielModifyResponse != "Success") {
                responseMessage += data.profielModifyResponse;
              }
              console.log("success");
            } else {
              responseMessage = data.response;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            responseMessage = err;
          })
          .always(function () {
            console.log("finished saveRestaurant");

            if (response == "Success") {
              //$(".resturant-message").html('<div class="v-messages__message message-transition-enter-to">' + responseMessage + '</div>');
              //alert(responseMessage);
              setTimeout(window.location.reload(), 5000);
            } else {
              $(".resturant-message").html(
                '<div class="v-text-field__details"><div class="v-messages theme--light error--text" role="alert"><div class="v-messages__wrapper"><div class="v-messages__message message-transition-enter-to">' +
                  responseMessage +
                  "</div></div></div></div>"
              );

              //alert(responseMessage);
            }
          });
      },
    },
    computed: {
      restaurantTypeEither: function () {
        let isEither = false;
        if (!!this.restaurantAdditionalType) {
          isEither = true;
        } else if (this.restaurantType.length > 0) {
          isEither = true;
        } else {
          isEither = false;
        }
        return isEither;
      },
    },

    watch: {
      restaurantType: {
        handler: function (val, oldVal) {
          // console.log(val);
          this.showRestaurantAdditionalType = val === "Autre";

          deep: true;
        },

        // async handler() {
        //   await this.$nextTick();
        //   this.$refs.restaurant_form.validate();
        // }
      },
      // restaurantAdditionalType: {
      //   async handler() {
      //     await this.$nextTick();
      //     this.$refs.restaurant_form.validate();
      //   }
      // }
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_recipeListing() {
  new Vue({
    el: "#PFS_recipeListing",
    data() {
      return {
        itemList: [],
        filterSettings: {
          sort: "",
          search_string: "",
          brand: [],
          dishTypes: [],
          restaurant_type: [],
        },

        filter_sort_values: window.filter_sort_values,
        filter_food_type: window.filter_food_type,
        filter_brand: window.filter_brand,
        filter_restaurant_type: window.filter_restaurant_type,

        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 4,
        hide_paginator: false,
      };
    },

    mixins: [getDataMixin],
    computed: {
      recipesList: function () {
        return this.itemList;
      },

      apiUrl: function () {
        return (
          API_BASE_URL +
          "/recipes?page=" +
          this.page +
          "&size=" +
          this.size +
          "&brands=" +
          this.filterSettings.brand +
          "&dishTypes=" +
          this.filterSettings.dishTypes +
          "&sortBy=" +
          this.getSort().by +
          "&restaurantTypes=" +
          this.filterSettings.restaurant_type +
          "&sortOrder=" +
          this.getSort().order +
          "&query=" +
          this.filterSettings.search_string
        );
      },
    },
    methods: {
      getSort() {
        const sortArray = this.filterSettings.sort.split(" ");
        return { by: sortArray[0], order: sortArray[1] };
      },
      onFilterChange() {
        this.page = 1;
        this.getData(this.apiUrl, true);
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl, false);
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_searchResults() {
  new Vue({
    el: "#PFS_searchResults",
    data() {
      return {
        itemList: [],
        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 6,

        hide_paginator: false,
      };
    },
    mixins: [getDataMixin],
    methods: {
      getSearchQueryFromUrl() {},
      showMore() {
        this.page++;
        this.getData(this.apiUrl, false);
      },
    },
    computed: {
      // Used for displaying searched term to user i page title
      queryString: function () {
        return getAllUrlParams().query;
      },
      apiUrl: function () {
        return (
          API_BASE_URL +
          "/search?query=" +
          getAllUrlParams().query +
          "&size=" +
          this.size +
          "&page=" +
          this.page
        );
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_productListing() {
  new Vue({
    el: "#PFS_productListing",
    data() {
      return {
        itemList: [],
        filterSettings: {
          search_string: "",
          brand: [],
        },
        filter_brand: window.filter_brand,

        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 4,
        hide_paginator: false,
      };
    },
    mixins: [getDataMixin],
    methods: {
      onFilterChange() {
        this.page = 1;
        this.getData(this.apiUrl, true);
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl, false);
      },
    },
    computed: {
      productList: function () {
        return this.itemList;
      },
      apiUrl: function () {
        return (
          API_BASE_URL +
          "/products?page=" +
          this.page +
          "&size=" +
          this.size +
          "&brands=" +
          this.filterSettings.brand +
          "&query=" +
          this.filterSettings.search_string
        );
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_newsListing() {
  new Vue({
    el: "#PFS_newsListing",
    data() {
      return {
        itemList: [],
        newsType: window.newsType,
        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 1,
        hide_paginator: false,
      };
    },
    mixins: [getDataMixin],

    computed: {
      newsList: function () {
        return this.itemList;
      },
      apiUrl: function () {
        return (
          API_BASE_URL +
          "/news?type=" +
          this.newsType +
          "&page=" +
          this.page +
          "&size=" +
          this.size
        );
      },
    },
    methods: {
      showMore() {
        this.page++;
        this.getData(this.apiUrl);
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_brandListing() {
  new Vue({
    el: "#PFS_brandListing",
    data() {
      return {
        brandList: [],
        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 100,
        hide_paginator: false,
      };
    },
    created: function () {
      this.getData(this.apiUrl);
    },
    computed: {
      apiUrl: function () {
        return (
          API_BASE_URL + "/brands?page=" + this.page + "&size=" + this.size
        );
      },
    },
    methods: {
      getData(url) {
        // Fetch Brands
        fetch(url)
          .then((response) => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
          })
          .then((data) => {
            for (var i of data.Results) {
              this.brandList.push(i);
            }
            this.totalResults = data.TotalResults;
            this.totalPages = data.TotalPages;
            this.currentlyLoaded = this.brandList.length;
            this.progress_percent = (
              (this.currentlyLoaded / this.totalResults) *
              100
            ).toFixed();
            if (this.page == this.totalPages) {
              this.hide_paginator = true;
            } else {
              this.hide_paginator = false;
            }
          });
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl);
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_equipmentListing() {
  new Vue({
    el: "#PFS_equipmentListing",
    data() {
      return {
        itemList: [],
        filterSettings: {
          search_string: "",
          brand: [],
        },
        filter_brand: window.filter_brand,

        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 4,
        hide_paginator: false,
      };
    },
    mixins: [getDataMixin],
    methods: {
      onFilterChange() {
        this.page = 1;
        this.getData(this.apiUrl, true);
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl, false);
      },
    },
    computed: {
      productList: function () {
        console.log(this.itemList)
        return this.itemList;
      },
      apiUrl: function () {
        return (
          
            "/api/equipments?uid=" + CATEGORY_ID + "&page=" +
            this.page +
            "&size=" +
            this.size +
            "&brands=" +
            this.filterSettings.brand +
            "&query=" +
            this.filterSettings.search_string
        );
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}


function PFS_savedRecipes() {
  new Vue({
    el: "#PFS_savedRecipes",
    data() {
      return {
        itemList: [],
        currentlyLoaded: 0,
        progress_percent: 0,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 4,
        hide_paginator: false,
        showErrorSnackbar: false,
        deleteErrorMsg: stringTranslation(
          "there_seems_to_be_some_technical_error"
        ),
      };
    },
    mixins: [getDataMixin],
    computed: {
      recipesList: function () {
        // TODO
        // remove dummy id-s from item list
        //////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.itemList.map((item, index) => {
        //     console.log(this.itemList[index]);
        //     return this.itemList[index].Id = this.page + '' + index
        // })
        // console.log('this.itemList :>> ', this.itemList);
        //////////////////////////////////////////////////////////////////////////////////////////////////////////

        return this.itemList;
      },

      apiUrl: function () {
        return (
          BASE_URL +
          SAVED_CONTENT_API_PATH +
          "?page=" +
          this.page +
          "&size=" +
          this.size
        );
      },
    },
    components: {
      "deletable-card-component": DeletableCardComponent,
    },

    methods: {
      isItemDeleted(value) {
        this.showErrorSnackbar = !value;
        if (value) {
          this.updateProgressbar(); // from mixin
        }
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl, false);
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_savedMenus() {
  new Vue({
    el: "#PFS_savedMenus",
    data() {
      return {
        itemList: [],
        // delete_dropdown_id: null,
        currentlyLoaded: 0,
        progress_percent: 0,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 4,
        hide_paginator: false,
        showErrorSnackbar: false,
        deleteErrorMsg: stringTranslation(
          "there_seems_to_be_some_technical_error"
        ),
      };
    },
    mixins: [getDataMixin],
    computed: {
      recipesList: function () {
        // TODO
        // remove dummy id-s from item list
        //////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.itemList.map((item, index) => {
        //     console.log(this.itemList[index]);
        //     return this.itemList[index].Id = this.page + '' + index
        // })
        // console.log('this.itemList :>> ', this.itemList);
        //////////////////////////////////////////////////////////////////////////////////////////////////////////

        return this.itemList;
      },

      apiUrl: function () {
        return (
          BASE_URL +
          SAVED_CONTENT_API_PATH +
          "?page=" +
          this.page +
          "&size=" +
          this.size
        );
      },
    },
    components: {
      "deletable-card-component": DeletableCardComponent,
    },
    methods: {
      isItemDeleted(value) {
        this.showErrorSnackbar = !value;
        if (value) {
          this.updateProgressbar(); // from mixin
        }
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl, false);
      },
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_popupPermissions() {
  console.log("Frpm PFS_popupPermissions");
  new Vue({
    el: "#PFS_popupPermissions",
    data() {
      return {
        popupPermissionsDialog: false,
        isPopupPermissionAccepted: false,
      };
    },
    methods: {
      acceptPopupPermission() {
        const data = { is_accepted: this.isPopupPermissionAccepted };
        var _this = this;
        _this.isPopupPermissionAccepted = false;
        jQuery
          .ajax({
            type: "POST",
            url: window.url_popup_permissions,
            data: data,
            dataType: "json",
          })
          .done(function (data) {
            if (data.status === "success") {
              _this.popupPermissionsDialog = false;
            } else {
              console.log("error: ", data.errorMessages);
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
          })
          .always(function () {
            _this.isPopupPermissionAccepted = true;
            // console.log("finished Popup Permission");
          });
      },
    },
    mounted() {
      // Open dialog if Permission is not already accepted
      this.popupPermissionsDialog = !window.is_popup_permissions_accepted;
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

// Old Photo library card
// Vue.component('photo_library_card', {
//     props: ['post'],
//     data: function () {
//         return {
//             title: this.post.Name,
//             image_thumbnail: this.post.Thumbnail,
//             downloadSize: null,
//             link: null,
//             isLink: false,
//             size: null,
//         }
//     },
//     watch: {
//         downloadSize: {
//             handler: function (val, oldVal) {
//
//                 window.dataLayer = window.dataLayer || [];
//                 window.dataLayer.push({
//                     'dlv.action': 'Download',
//                     'dlv.type': 'Phototeque',
//                     'dlv.asset': this.post.Name
//                 });
//
//                 this.size = val;
//                 if (this.downloadSize) {
//                     this.isLink = true
//                 }
//
//                 if (val === 'small') {
//                     this.link = this.post.LogoSmall
//                 } else {
//                     this.link = this.post.LogoBig
//                 }
//             },
//             deep: true
//         },
//
//     },
//     methods: {
//         handleFileDownload() {
//
//             downloadFile(this.link, this.title, this.size)
//
//             this.downloadSize = null
//             this.link = null
//             this.isLink = false
//             this.size = null
//         }
//     }
// })

// ==============================================================================================================================
// Photo Library card with Platform
// ------------------------------------------------------------------------------------------------------------------------------
Vue.component("photo_library_card", {
  props: ["post"],
  data: function () {
    return {
      title: this.post.Name,
      image_thumbnail: this.post.Thumbnail,
      imageId: this.post.ImageId,
      thumbnail: "false",
      platform: window.filter_platform[0].value,
      resizeType: this.post.ResizeOptions,
      isResizable: false,
      filter_platform: window.filter_platform,
    };
  },

  methods: {
    checkIfResizable() {
      if (!this.resizeType || this.resizeType === "NoResize") {
        this.isResizable = false;
      } else {
        this.isResizable = true;
      }
    },

    handleFileDownload() {
      // Create link to image
      const downloadImageUrl =
        PHOTOLIBITEMS_DOWNLOAD_BASE_URL +
        "/image/format?" +
        "imageId=" +
        this.imageId +
        "&thumbnail=" +
        this.thumbnail +
        "&platform=" +
        this.platform +
        "&resizeType=" +
        this.resizeType;

      // console.log("downloadImageUrl >> ", downloadImageUrl)

      // Download image
      downloadFile(downloadImageUrl, this.title, "");
    },
  },
  mounted() {
    // For every card check is resizable and show/hide platform select options
    this.checkIfResizable();
  },
});

// ------------------------------------------------------------------------------------------------------------------------------

function PFS_photoLibraryListing() {
  new Vue({
    el: "#PFS_photoLibraryListing",
    data() {
      return {
        itemList: [],
        filterSettings: {
          sort: "",
          search_string: "",
          brand: [],
          type_of_visuals: [],
        },

        filter_sort_values: window.filter_sort_values,
        filter_category: window.filter_category,
        filter_brand: window.filter_brand,
        filter_type_of_visuals: window.filter_type_of_visuals,

        currentlyLoaded: 0,
        progress_percent: 10,
        totalPages: 0,
        totalResults: 0,
        page: 1,
        size: 4,
        hide_paginator: false,
      };
    },
    mixins: [getDataMixin],
    methods: {
      getSort() {
        const sortArray = this.filterSettings.sort.split(" ");
        return { by: sortArray[0], order: sortArray[1] };
      },
      onFilterChange() {
        this.page = 1;
        this.getData(this.apiUrl, true);
      },
      showMore() {
        this.page++;
        this.getData(this.apiUrl);
      },
    },
    computed: {
      photoLibraryListing: function () {
        return this.itemList;
      },
      apiUrl: function () {
        return (
          API_BASE_URL +
          "/photolibitems?page=" +
          this.page +
          "&size=" +
          this.size +
          "&brands=" +
          this.filterSettings.brand +
          "&typeOfVisuals=" +
          encodeURIComponent(this.filterSettings.type_of_visuals) +
          "&sortBy=" +
          this.getSort().by +
          "&sortOrder=" +
          this.getSort().order +
          "&query=" +
          this.filterSettings.search_string
        );
      },
    },

    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_ContactForm() {
  new Vue({
    el: "#PFS_ContactForm",
    data() {
      return {
        email: "",
        message: "",
        name: "",
        isLoading: false,
        server_error: false,
        server_error_msg: "",

        contact_success: false,
        contact_success_msg: "",

        isContactFormValid: false,

        nameRules: [(v) => !!v || stringTranslation("name_is_required")],
        messageRules: [(v) => !!v || stringTranslation("message_is_required")],
        emailRules: [
          (v) => !!v || stringTranslation("e_mail_is_required"),
          (v) =>
            emailPattern.test(v) || stringTranslation("e_mail_must_be_valid"),
        ],
      };
    },
    methods: {
      submitForm() {
        const _this = this;
        _this.isLoading = true;
        var contactFromData = {
          Email: this.email,
          Message: this.message,
          Name: this.name,
        };

        jQuery
          .ajax({
            type: "POST",
            url: window.page_data__contact_path,
            data: contactFromData,
            dataType: "json",
          })
          .done(function (data) {
            if (data.status == "Success") {
              _this.contact_success = true;
            } else {
              _this.contact_success = false;
              _this.server_error_msg = data.message;
              _this.server_error = true;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            _this.server_error_msg = stringTranslation(
              "we_apologize_something_went_wrong_please_try_again_later"
            );
            _this.server_error = true;
          })
          .always(function () {
            _this.isLoading = false;
          });
      },
    },
    updated: function () {
      this.$nextTick(function () {
        if (
          this.email !== "" &&
          emailPattern.test(this.email) &&
          this.name !== "" &&
          this.message !== ""
        ) {
          this.isContactFormValid = true;
        } else {
          this.isContactFormValid = false;
        }
      });
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_SecondaryContactForm() {
  new Vue({
    el: "#PFS_SecondaryContactForm",
    data() {
      return {
        email: "",
        message: "",
        first_name: "",
        last_name: "",
        postal_code: "",
        restaurant_name: "",
        activity_type: window.activity_type,
        selected_activity_type: "",
        isLoading: false,
        server_error: false,
        server_error_msg: "",
        newsletter: false,
        contactOptIn: false,

        contact_success: false,
        contact_success_msg: "",

        isContactFormValid: false,

        nameRules: [(v) => !!v || stringTranslation("name_is_required")],
        lastNameRules: [(v) => !!v || stringTranslation("surname_is_required")],
        postalCodeRules: [(v) => !!v || stringTranslation("postal_code_is_required")],
        restaurantNameRules: [(v) => !!v || stringTranslation("restaurant_name_is_required")],
        messageRules: [(v) => !!v || stringTranslation("message_is_required")],
        activityTypeRules: [(v) => !!v || stringTranslation("this_field_is_required")],
        emailRules: [
          (v) => !!v || stringTranslation("e_mail_is_required"),
          (v) =>
            emailPattern.test(v) || stringTranslation("e_mail_must_be_valid"),
        ],
        // newsletterRules: [(v) => !!v || stringTranslation("this_field_is_required")],
        // contactOptInRules: [(v) => !!v || stringTranslation("this_field_is_required")],
      };
    },
    methods: {
      submitForm() {
        const _this = this;
        _this.isLoading = true;
        var contactFromData = {
          Email: this.email,
          Message: this.message,
          FirstName: this.first_name,
          LastName: this.last_name,
          PostalCode: this.postal_code,
          RestaurantName: this.restaurant_name,
          ActivityType: this.selected_activity_type,
          Newsletter: this.newsletter,
          ContactOptIn: this.contactOptIn,
          
        };

        jQuery
          .ajax({
            type: "POST",
            url: window.page_data__contact_path,
            data: contactFromData,
            dataType: "json",
          })
          .done(function (data) {
            if (data.status == "Success") {
              _this.contact_success = true;
            } else {
              _this.contact_success = false;
              _this.server_error_msg = data.message;
              _this.server_error = true;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            _this.server_error_msg = stringTranslation(
              "we_apologize_something_went_wrong_please_try_again_later"
            );
            _this.server_error = true;
          })
          .always(function () {
            _this.isLoading = false;
          });
      },
    },
    updated: function () {
      this.$nextTick(function () {
        if (
          this.email !== "" &&
          emailPattern.test(this.email) &&
          this.first_name !== "" &&
          this.message !== "" &&
          this.last_name !== "" &&
          this.postal_code !== "" &&
          this.restaurant_name !== "" &&
          this.selected_activity_type !== ""
        ) {
          this.isContactFormValid = true;
        } else {
          this.isContactFormValid = false;
        }
      });
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

function PFS_Contest() {
  new Vue({
    el: "#PFS_Contest",
    data() {
      return {
        FirstName: window.model.FirstName,
        LastName: window.model.LastName,
        Email: window.model.Email,
        PhoneNumber: window.model.PhoneNumber,
        Venue: window.model.Venue,
        SiretNumber: window.model.SiretNumber,
        PurchasedFrom: "",
        InvoiceImage: null,
        tos: false,
        Subscribed: false,
        message: "",
        isLoading: false,
        server_error: false,
        server_error_msg: [],

        contest_success: false,
        contest_success_msg: "",
        siretNumberLength: 14,
        siretNumberPattern: /^[0-9]{14}/,
        phoneNumberPattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,

        isContestFormValid: false,

        firstNameRules: [
          (v) => !!v || stringTranslation("first_name_is_required"),
        ],
        surnameRules: [(v) => !!v || stringTranslation("surname_is_required")],
        phoneNumberRules: {
          required: (v) => !!v || stringTranslation("phone_number_is_required"),
          regex: (v) => {
            return (
              this.phoneNumberPattern.test(v) ||
              stringTranslation("phone_number_is_required")
            );
          },
        },
        requiredRules: [
          (v) => !!v || stringTranslation("this_field_is_required"),
        ],
        tosRules: [
          (v) => !!v || stringTranslation("terms_of_service__is_required"),
        ],
        emailRules: [
          (v) => !!v || stringTranslation("e_mail_is_required"),
          (v) =>
            emailPattern.test(v) || stringTranslation("e_mail_must_be_valid"),
        ],
        invoiceImageRules: [
          (v) => !!v || stringTranslation("this_field_is_required"),
          // v => v && v.type === 'image/png' || stringTranslation('file_extension_must_be_png')
          (v) =>
            this.validateFileExtension(v) ||
            stringTranslation("file_extension_must_be_png"),
        ],
        siretNumberRules: {
          required: (v) => !!v || stringTranslation("this_field_is_required"),
          numeric: (v) => {
            return (
              this.siretNumberPattern.test(v) ||
              stringTranslation("only_numbers")
            );
          },
          numLength: (v) =>
            (!!v && v.length === this.siretNumberLength) ||
            stringTranslation("must_be_") +
              this.siretNumberLength +
              stringTranslation("_characters"),
        },
      };
    },
    methods: {
      submitForm() {
        const _this = this;
        _this.isLoading = true;

        let antiforgerytoken = null;

        if (document.querySelector('[name="__RequestVerificationToken"]')) {
          antiforgerytoken = document.querySelector(
            '[name="__RequestVerificationToken"]'
          ).value;
        }

        let body = new FormData();
        body.append("Email", this.Email);
        body.append("Venue", this.Venue);
        body.append("LastName", this.LastName);
        body.append("FirstName", this.FirstName);
        body.append("PhoneNumber", this.PhoneNumber);
        body.append("SiretNumber", this.SiretNumber);
        body.append("InvoiceImage", this.InvoiceImage);
        body.append("Subscribed", this.Subscribed);
        body.append("PurchasedFrom", this.PurchasedFrom);
        if (antiforgerytoken) {
          body.append("__RequestVerificationToken", antiforgerytoken);
        }

        jQuery
          .ajax({
            type: "POST",
            url: window.contest_api_path,
            data: body,
            enctype: "multipart/form-data",
            processType: false,
            processData: false,
            contentType: false,
          })
          .done(function (data) {
            if (data.status == "success") {
              _this.contest_success = true;
            } else {
              _this.contest_success = false;
              _this.server_error_msg = data.errorMessages;
              _this.server_error = true;
            }
          })
          .fail(function (err) {
            console.log("error: ", err);
            _this.server_error_msg = [
              stringTranslation(
                "we_apologize_something_went_wrong_please_try_again_later"
              ),
            ];
            _this.server_error = true;
          })
          .always(function () {
            _this.isLoading = false;
          });
      },
      validateFileExtension(image) {
        if (!image) {
          return false;
        }
        const allowedExtensions = ["png", "jpg", "jpeg"];
        const ext = image.type.split("/")[1].toLowerCase();

        if (allowedExtensions.includes(ext)) {
          return true;
        }

        return false;
      },
    },
    updated: function () {
      this.$nextTick(function () {
        if (
          this.Email !== "" &&
          emailPattern.test(this.Email) &&
          this.FirstName !== "" &&
          this.LastName !== "" &&
          this.Venue !== "" &&
          this.PhoneNumber !== "" &&
          this.phoneNumberPattern.test(this.PhoneNumber) &&
          this.SiretNumber !== "" &&
          this.SiretNumber.length === this.siretNumberLength &&
          this.siretNumberPattern.test(this.SiretNumber) &&
          this.InvoiceImage &&
          this.validateFileExtension(this.InvoiceImage) &&
          this.tos
        ) {
          this.isContestFormValid = true;
        } else {
          this.isContestFormValid = false;
        }
      });
    },
    vuetify: new Vuetify(VUETIFY_OPTIONS),
  });
}

// Shared functions

function calculateProgress(total, loaded) {
  return Math.round((loaded / total) * 100);
}

function isEmailExist(email) {
  var isEmailExist = true;
  jQuery
    .ajax({
      type: "POST",
      url: url_isEmailExist,
      data: { emailId: email },
      dataType: "json",
    })
    .done(function (data) {
      console.log("success");
      isEmailExist = data.Item1;
    })
    .fail(function (err) {})
    .always(function () {
      return isEmailExist;
    });

  return true;
}

function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
